<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <!-- <div  v-for="item in index" :key="item">{{item.id}} </div> -->

      <!-- {{index}} -->
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/corcardsoperdetails/' + index" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
        </router-link>

        <router-link :to="'/corcardupdate/' + index" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-pen text-warning"></i>
          </span>
          <span class="navi-text">Update</span>
        </router-link>

        <button @click="corcardelete(index)" class="navi-link">
          <span class="navi-icon"
            ><i class="flaticon2-trash text-danger"></i
          ></span>
          <span class="navi-text">Delete</span>
        </button>
      </b-dropdown-text>
      <!--      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-graph-1"></i>
          </span>
          <span class="navi-text">Order</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Event</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-layers-1"></i>
          </span>
          <span class="navi-text">Report</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Post</span>
        </a>
      </b-dropdown-text>-->
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss" scoped>
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import axios from "axios";
export default {
  name: 'DriverAction',
  props: {
    allUsers: Array,
    btnClass: String,
    index: Number,
    id: Number
  },
  beforeCreate() {
    this.$store.dispatch('getAllCorCards')
  },
  computed: {
    getAllCorCards() {
      return this.$store.state.requests.AllCorCards
    }
  },

  methods: {
    corcardelete(value) {
      this.$store.dispatch('deleteCorpCard', value)
    }
  },
  components: {},
  mounted() {}
}
</script>
