<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.BANK_KASSA.CARDS.CARD_OPER') }}
              </h3>
            </div>

            <div class="card-button">
              <!--              <div>
                <v-btn
                  small
                  to="/addcorcardsoper"
                  :class="'ml-auto'"
                  color="primary"
                  >Operatsiya +</v-btn
                >
              </div> -->
            </div>
          </div>

          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="getAllCorCardsList"
              :search="search"
              @click:row="rowClick"
              single-select
              no-data-text="Malumot kiritilmagan"
            >
              <template v-slot:item.oper_date="{ item }">
                {{ item.oper_date | dateFilter }}
              </template>

              <template late v-slot:item.number_16_digit="{ item }">
                <!-- {{ item.remain }} -->
                <!-- <input type="text" disabled v-model="item.remain" v-money="money" /> -->
                <input
                  style="background: transparent; border: none"
                  v-model="item.number_16_digit"
                  type="text"
                  disabled
                />
              </template>
              <template v-slot:item.remain="{ item }">
                <!-- {{ item.remain }} -->
                <!-- <input type="text" disabled v-model="item.remain" v-money="money" /> -->
                <input
                  style="background: transparent; border: none"
                  v-model="item.remain"
                  type="text"
                  disabled
                />
              </template>
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.action="{ item }">
                <CorCardsOpeAction :index="item.id"></CorCardsOpeAction>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.v-data-table >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 1rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 0.9rem !important; */
  color: #000 !important;
}
.v-data-table__wrapper {
  overflow-x: initial !important;
  overflow-y: initial !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.077) !important;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CorCardsOpeAction from '@/view/content/dropdown/CorCardsOpeAction.vue'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      index: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      index2: '',
      menu2: false,
      card_add: false,
      card_remove: false,
      search: ''
    }
  },
  components: {
    CorCardsOpeAction
  },
  // Action
  beforeCreate() {
    this.$store.dispatch('getCorpCardsOperList')
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        console.log(item.id)
        this.$router.push('/corcardsoperdetails/' + item.id + '/')
        row.select(false)
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$t('TABLE_HEADER.OPERATION_TYPE'),
          value: 'operation_type_name'
        },
        { text: this.$t('TABLE_HEADER.CARD_NUMBER'), value: 'card_number' },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        { text: this.$t('TABLE_HEADER.AMOUNT'), value: 'amount' },
        { text: null, value: 'action', sortable: false }
      ]
    },
    getAllCorCardsList() {
      const data = this.$store.state.requests.corpCardsOperList.results
      console.log('data --- -- ', this.$store.requests.corpCardsOperList.results)
      return data
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.BANK_KASSA.TITLE') },
      { title: this.$t('MENU.BANK_KASSA.CARDS.CARD_OPER') }
    ])
  }
}
// Style
</script>
